import React from 'react';
import { Col, Row } from 'react-grid-system';
import HeroBanner from '../components/organisms/heroBanner';
import Typography from '../components/atoms/typography';
import Spacer from '../components/atoms/spacer';
import Button from '../components/atoms/button';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';
import background from '../images/product-background.png';
import panboxRight from '../images/panbox-right.png';
import PriceTag from '../components/molecules/priceTag';
import { productCards } from '../services/pageData';
import InfoCard from '../components/molecules/infoCard';
import girlAndMan from '../images/girl-man.png';
import theme from '../styles/theme';
import ContactForm from '../components/organisms/contactForm';
import Container from '../components/atoms/container';
import SEO from '../components/seo';
import useScreenClassHelper from '../hooks/useScreenClassHelper';
import Routes from '../services/routes';

const Image = styled.img`
  width: 100%;
  margin: auto;
`;

const PriceTagWrapper = styled.div`
  position: absolute;
  ${(props: { isMobile: boolean }) =>
    !props.isMobile &&
    css`
      top: 10px;
      left: 40px; ;
    `}
`;
const PageWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.formBackground};
`;

const ContactFormWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
`;

const ContainerRightHero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const StyledSubHeader = styled(Typography)`
  font-weight: normal;
`;

const Produkter = () => {
  const handleClick = (route: string) => {
    navigate(route);
  };
  const { IS_MOBILE } = useScreenClassHelper();

  return (
    <>
      <SEO
        title="pandemikit och skydd vid virusutbrott | panBox"
        description="Var förberedd vid nästa virusutbrott med pandemikit från panBox. Med vår lösning slipper du oroa dig och kan känna dig säker."
      />
      <PageWrapper theme={theme}>
        <HeroBanner
          backgroundImage={background}
          leftPane={
            <>
              <Row>
                <Col md={12}>
                  <Spacer spacing={10} />
                  <Typography color="textSecondary" variant="header">
                    Pandemikit och skydd vid virusutbrott
                  </Typography>
                  <Spacer />
                  <StyledSubHeader color="textSecondary" variant="h3">
                    Var förberedd vid nästa virusutbrott med noggrant utvalda
                    produkter.
                  </StyledSubHeader>
                  <Spacer />
                </Col>
                <Col md={6}>
                  <Button onClick={() => handleClick('/bestall')}>
                    Boka din panBox nu!
                  </Button>
                </Col>
              </Row>
              <Spacer spacing={4} />
            </>
          }
        />
        <Spacer spacing={4} />
        <Container>
          <Row>
            <Col md={9} lg={8}>
              <Typography variant="h2" color="primary">
                Vad är panBox?
              </Typography>
              <Spacer />
              <Typography>
                När pandemin slog till i Sverige med corona och covid-19 så tog
                många produkter slut både i butik och online. Det gick helt
                plötsligt inte att köpa, eller beställa, vare sig handsprit,
                munskydd, skyddshandskar eller liknande. Det är även svårt att
                få tag på skydd i influensatider.
              </Typography>
              <Spacer />
              <Typography>
                För att du ska vara förberedd och känna dig trygg vid nästa
                virusutbrott och influensa har vi skapat panBox.
              </Typography>
              <Spacer />
              <Typography>
                I vår beredskapsbox hittar du det du behöver för att minska
                risken för att du och de runt omkring dig ska smittas av virus.
              </Typography>
              <Spacer />
              <Typography>
                När du beställer en panBox förvarar vi den i vårt
                beredskapslager åt dig och när nästa virusutbrott bryter ut kan
                du känna dig trygg att du snabbt och smidigt kan den hemskickad.
                Med vår lösning tar du både ditt samhällsansvar och skyddar
                samtidigt dig och dina nära och kära.
              </Typography>
              <Spacer />
              <Typography>
                Eller som vi kallar det, <i>Trygghet i en box.</i>
              </Typography>
            </Col>
          </Row>
          <Spacer spacing={4} />
          <Row justify="center" align="center">
            <Col md={5}>
              <>
                <Typography color="primary" variant="h2">
                  panBox innehåll
                </Typography>
                <Spacer />
                <Typography>
                  Alla våra produkter syftar till att skydda dig och din familj
                  vid virusutbrott och i förlängningen begränsa smittspridning i
                  samhället. Vi har därför samlat skyddsprodukter som är viktiga
                  att ha vid ett virusutbrott i en smidig låda, som vi
                  lagerhåller åt dig tills du behöver den.
                </Typography>
                <Typography>
                  Nedan kan du se och läsa mer om vad som ingår i panBox.
                </Typography>
              </>
            </Col>
            <Col md={6}>
              <PriceTagWrapper isMobile={IS_MOBILE}>
                <PriceTag />
              </PriceTagWrapper>
              <Image src={panboxRight} alt="panBox blå" />
            </Col>
          </Row>
          <Spacer spacing={4} />
          <Row>
            {productCards.map((item) => (
              <Col key={item.title} md={4}>
                <InfoCard
                  type="product"
                  title={item.title}
                  description={item.description}
                  image={item.image}
                  productSpecification={item.productSpecification}
                  productInfo={item.productInfo}
                />
              </Col>
            ))}
          </Row>
        </Container>

        <Spacer spacing={4} />
        <HeroBanner
          backgroundColor="secondary"
          leftPane={
            <Row>
              <Col md={12}>
                <Spacer spacing={3} />
                <Typography color="textSecondary" variant="header">
                  Säkra din panBox idag!
                </Typography>
              </Col>
              <Col md={6}>
                <Spacer />
                <Button primary onClick={() => handleClick(Routes.ORDER)}>
                  Boka din panBox nu
                </Button>
              </Col>
            </Row>
          }
          rightPane={
            <ContainerRightHero>
              <Spacer spacing={4} />
              <Image alt="tjej" src={girlAndMan} />
            </ContainerRightHero>
          }
        />
        {/* <Spacer spacing={4} /> */}
        <ContactFormWrapper theme={theme}>
          <Container>
            <Row>
              <Col md={10} offset={{ md: 1 }}>
                <ContactForm
                  header={
                    <>
                      <Spacer spacing={4} />
                      <Typography
                        align="center"
                        color="textSecondary"
                        variant="h2"
                      >
                        Har du frågor om panBox?
                      </Typography>
                      <Typography
                        align="center"
                        color="textSecondary"
                        variant="h3"
                      >
                        Skicka ett meddelande till oss så återkommer vi inom
                        kort!
                      </Typography>
                      <Spacer spacing={2} />
                    </>
                  }
                  ctaTitle="Skicka"
                  termsColor="textSecondary"
                  backgroundColor="primary"
                />
              </Col>
            </Row>
          </Container>
          <Spacer spacing={3} />
        </ContactFormWrapper>
      </PageWrapper>
    </>
  );
};

export default Produkter;
